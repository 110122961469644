<template>
  <div class="form-step">
    <div class="details-left">
      Insurance
    </div>
    <div class="details-right">
      {{step}}/{{steps}}
    </div>
    <div class="title-box">
      <div class="section-title">
        Upload your insurance card.
      </div>
      <p class="section-paragraph">
        Please upload a picture of the front and backside of your insurance card.
      </p>
    </div>
    <div class="form-fields">
      <div class="insurance-photo-wrapper">
        <div v-if="formData.insuranceFront == undefined" class="card-content">
          <span class="card-title">Front</span><br>
          <span class="card-icon">
            <b-icon icon="camera-fill"></b-icon>
          </span><br>
          <span class="card-subtitle">Click or drag to upload image</span>
        </div>
        <img v-if="formData.insuranceFront != undefined" class="insurance-photo" :src="selectImage('front')"/>
        <b-form-file
          v-model="formData.insuranceFront"
          plain
          accept="image/*"
          v-on:input="updateData()"
          class="upload-input"
        ></b-form-file>
        <span v-if="formData.insuranceFront != undefined" class="clear-input">Change Photo</span>
      </div>
      <div class="insurance-photo-wrapper">
        <div v-if="formData.insuranceBack == undefined" class="card-content">
          <span class="card-title">Back</span><br>
          <span class="card-icon">
            <b-icon icon="camera-fill"></b-icon>
          </span><br>
          <span class="card-subtitle">Click or drag to upload image</span>
        </div>
        <img v-if="formData.insuranceBack != undefined" class="insurance-photo" :src="selectImage('back')"/>
        <b-form-file
          v-model="formData.insuranceBack"
          plain
          accept="image/*"
          v-on:input="updateData()"
          class="upload-input"
        ></b-form-file>
        <span v-if="formData.insuranceBack != undefined" class="clear-input">Change Photo</span>
      </div>
    </div>
    <a class="btn-next" @click="noInsurance()">
      I don't have insurance
    </a>
  </div>
</template>

<script>
export default {
  name: 'StepFour',
  props: {
    step: Number,
    steps: Number,
    data: Object
  },
  data () {
    return {
      formData: this.data
    }
  },
  methods: {
    updateData() {
      this.$emit('updateData', this.formData)
    },
    noInsurance() {
      this.$emit('updateData', this.formData)
      this.$emit('noInsurance')
    },
    selectImage(side) {
      if (side == 'front') {
        return URL.createObjectURL(this.formData.insuranceFront);
      }
      if (side == 'back') {
        return URL.createObjectURL(this.formData.insuranceBack);
      }
    },
  }
}
</script>

<style scoped>
.details-left {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 15px;
  margin-left: 15px;
  color: #40805b;
  font-size: 18px;
}
.details-right {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  margin-right: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(32,38,44,.38);
  font-size: 18px;
}
.title-box {
  display: flex;
  padding-top: 100px;
  margin-bottom: 35px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.section-title {
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 12px;
  font-size: 50px;
  font-weight: 600;
}
.section-paragraph {
  max-width: 500px;
  font-weight: 400;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 10px;
}
.form-fields {
  max-width: 550px;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-bottom: 5px;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 70px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.text-field {
  height: auto;
  margin-bottom: 0;
  padding: 20px 30px;
  border: 1px #000;
  border-radius: 33px;
  background-color: #eaebf0;
  color: #000;
  font-size: 20px;
  display: block;
  width: 100%;
  line-height: 1.42857143;
  margin: 0;
}
.insurance-photo-wrapper {
  width: 220px;
  height: 220px;
  align-items: flex-end;
  overflow: hidden;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0 30px 100px 0 rgb(60 60 60 / 20%), inset 0 34px 100px 0 rgb(60 60 60 / 10%);
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.insurance-photo{
  width: 220px;
  height: 220px;
  border-radius: 50px;
  object-fit: cover;
  position: absolute;
}
.upload-input {
  width: 220px;
  height: 220px;
  border-radius: 50px;
  position: absolute;
  opacity: 0;
}
.clear-input {
  width: 100%;
  background-color: #7a7a7ad0;
  z-index: 1;
  color: #fff;
  padding-bottom: 6px;
  padding-top: 6px;
}
.card-content {
  color: #000;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  padding: 20px;
}
.card-title {
  font-size: 22px;
  font-weight: 600;
}
.card-icon {
  color: #40805b;
  font-size: 68px;
}
.card-subtitle {
  font-size: 14px;
}
@media (max-width: 780px) {
  .section-title {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 12px;
    font-size: 32px;
    font-weight: 600;
  }
}
.btn-next{
    margin-left: auto;
    margin-top: 50px;
    display: inline-block;
    font-family: Futurapt,sans-serif;
    color: #ff5666;
    font-size: 18px;
    line-height: 1.65;
    font-weight: 700;
    text-align: center;
    text-decoration: underline;
}
</style>