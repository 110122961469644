<template>
  <div id="app">
    <header>
      <header-component />
    </header>
    <router-view />
  </div>
</template>

<script>
import HeaderComponent from './components/header-component.vue'

export default {
  name: 'App',
  components: {
    HeaderComponent
  }
}
</script>

<style>
@font-face {
  font-family: "Futurapt";
  src: local("Futurapt"),
   url(./assets/fonts/futura-pt/FuturaPTMedium.otf) format("truetype");
}
html,body,#app {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden; 
}
body {
  margin: 0;
  min-height: 100vh;
  background-color: #eaebf0;
  font-family: Futurapt,sans-serif;
  color: #20262c;
  font-size: 22px;
  line-height: 1.25;
}
#app {
    background-color: #f0efec;
    background-image: url(assets/images/bg-template-top.svg);
    background-position: 50% 0;
    background-size: 3840px;
    background-repeat: no-repeat;
    margin: 0;
    min-height: 100vh;
    width: 100%;
}
header {
  display: block;
}
</style>
