<template>
  <div class="form-step">
    <div class="details-left">
      Location
    </div>
    <div class="details-right">
      {{step}}/{{steps}}
    </div>
    <div class="title-box">
      <div class="section-title">
        Where are you located?
      </div>
      <p class="section-paragraph">
        Please enter your address below.
      </p>
    </div>
    <div class="form-fields">
      <input type="text" ref="gpaaddress" class="text-field" placeholder="Enter your address" v-model="formData.fullAddress" v-on:input="updateData()">
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepThree',
  props: {
    step: Number,
    steps: Number,
    data: Object
  },
  data () {
    return {
      formData: this.data
    }
  },
  mounted () {
    // eslint-disable-next-line
    const autocomplete = new google.maps.places.Autocomplete(this.$refs["gpaaddress"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      console.log(place);
      let street_num = this.findComponent("street_number", place.address_components)
      let street_name = this.findComponent("route", place.address_components)
      this.formData.address = {
        line1: street_num + " " + street_name,
        line2: '',
        city: this.findComponent("locality", place.address_components),
        state: this.findComponent("administrative_area_level_1", place.address_components),
        country: this.findComponent("country", place.address_components),
        zip: this.findComponent("postal_code", place.address_components)
      }
      this.formData.fullAddress = `${this.formData.address.line1}, ${this.formData.address.city}, ${this.formData.address.state} ${this.formData.address.zip}`
    })
  },
  methods: {
    updateData() {
      this.$emit('updateData', this.formData)
    },
    findComponent(nameKey, addressComponents) {
      for (var i=0; i < addressComponents.length; i++) {
          if (addressComponents[i].types[0] === nameKey) {
              return addressComponents[i].short_name
          }
      }
    }
  }
}
</script>

<style scoped>
.details-left {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 15px;
  margin-left: 15px;
  color: #40805b;
  font-size: 18px;
}
.details-right {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  margin-right: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(32,38,44,.38);
  font-size: 18px;
}
.title-box {
  display: flex;
  padding-top: 100px;
  margin-bottom: 35px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.section-title {
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 12px;
  font-size: 50px;
  font-weight: 600;
}
.section-paragraph {
  max-width: 500px;
  font-weight: 400;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 10px;
}
.form-fields {
  max-width: 550px;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-bottom: 5px;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
  margin-top: 70px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}
.text-field {
  height: auto;
  margin-bottom: 0;
  padding: 20px 30px;
  border: 1px #000;
  border-radius: 33px;
  background-color: #eaebf0;
  color: #000;
  font-size: 20px;
  display: block;
  width: 100%;
  line-height: 1.42857143;
  margin: 0;
}
@media (max-width: 780px) {
  .section-title {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 12px;
    font-size: 32px;
    font-weight: 600;
  }
}
</style>